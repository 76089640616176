.errorContainer {
  display: flex;
  flex-direction: column;
  align-self: center;

  @media (--v-from-md) {
    padding: var(--v-space-48);
  }

  @media (--v-from-lg) {
    padding: var(--c-space-96);
  }
}
